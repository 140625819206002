<!-- eslint-disable max-lines -->
<template>
    <paper v-loading="btnloading" class="mip-iframe">
        <div class="top-box">
            <!-- 标题区域 -->
            <div class="header-box">
                <h4>战合客户信息修改申请</h4>
                <div class="header-info-crm">
                    <div class="tips">合作模式说明：{{ detailDataLocal.accessWarn }}</div>
                    <div class="apply-info">
                        <div>申请人：{{ detailDataLocal.applicantName }}</div>
                        <div>部门：{{ detailDataLocal.applicantDepartName }}</div>
                    </div>
                </div>
            </div>
            <!-- 按钮区域 -->
            <div class="detail-toolBar">
                <el-tooltip class="item-flow-right" effect="dark" content="刷新" placement="bottom">
                    <lots-button
                        size="mini"
                        icon="el-icon-refresh"
                        class="refresh"
                        @click="refleshPage()"
                        :loading="detailLoading">
                    </lots-button>
                </el-tooltip>
            </div>
        </div>
        <div class="strategic-cooperation-introduce-apply"
            v-loading="detailLoading">
            <!-- 表单区域 -->
            <collapse-infos
                ref="upholdHealder"
                :detailData="detailDataLocal"
                :collapse="COLLAPSE"
                :activeCollapses="activeCollapses"
                @change="handleInfosChange">
                <!-- 折叠筐标题插槽 -->
                <template v-slot:collapse-title-append="{collapseItem}">
                    <span >{{ collapseItem.title }}</span>
                </template>
                <!-- 表单字段插槽 -->
                <template v-slot:collapse-append="{form, itemSetting, rIndex, collapseItem, dictData}">
                    <span v-if="itemSetting.prop === 'relatedFiles'">
                        <lots-upload-button :text="'上传附件'" :bucket="'annto-lcrm'" :disabled="readonly"
                            @callback="(data) => { upLoadData(form, itemSetting, data); }">
                        </lots-upload-button>
                        <div class="file-infos">
                            <template v-if="form[itemSetting.prop]
                                && form[itemSetting.prop].length">
                                <div
                                    class="file-list"
                                    v-for="(item, index) in form[itemSetting.prop]"
                                    :key="index + item.fileName">
                                    <div
                                        class="file-name upload"
                                        :title="item.fileName">
                                        {{`${index+1}. `}}
                                        <a :href="item.fileUrl" target="_blank">{{ item.fileName }}</a>
                                    </div>
                                    <div class="delete-btn">
                                        <el-button
                                            type="text"
                                            plain>
                                            <a :href="item.fileUrl" target="_blank">下载</a>
                                        </el-button>
                                        <el-button
                                            type="text"
                                            :disabled="readonly"
                                            plain
                                            @click="handleDeleteFile(form, itemSetting, index)">删除</el-button>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </span>
                    <div
                        v-else-if="['salesChannelsList', 'categoryStructureList', 'proxyBrandList'].includes(itemSetting.prop)"
                        :class="{
                            'width-small': itemSetting.prop === 'salesChannelsList',
                            'width-middle': itemSetting.prop === 'categoryStructureList',
                            'width-large': itemSetting.prop === 'proxyBrandList'
                        }">
                        <el-table
                            :border="true"
                            :data="tableData(itemSetting.prop)"
                            :height="tableHeight(itemSetting.prop)">
                            <el-table-column
                                v-for="(col, cIndex) in itemSetting.columns"
                                :key="col.prop + cIndex"
                                :prop="col.prop"
                                :label="col.label"
                                :show-overflow-tooltip="lodash.get(col, 'showTooltip', true)"
                                v-bind="{ ...col, type: lodash.get(col, 'columnType', '') }">
                                <template v-slot="{ row, $index: rIndex }">
                                    <div v-if="col.type === 'select'">
                                        <span v-if="dictData[col.optionsKey] && row[col.prop]">{{ dictData[col.optionsKey][row[col.prop]] || row[col.prop] ||'' }}</span>
                                    </div>
                                    <div v-else>{{ row[col.prop] || '' }}</div>
                                </template>
                            </el-table-column>
                        </el-table>
                    </div>
                    <!-- 评分表明细 -->
                    <div v-else-if="itemSetting.prop === 'ratingScoreList'" class="rating-score-list-box">
                        <div
                            v-for="(scoreItem, index) in newScoreConfig"
                            :key="index"
                            class="score-config-item-box">
                            <div class="item-tittle">{{ scoreItem.title }}：</div>
                            <div class="item-content">
                                <dc-form
                                    ref='scoreDcForm'
                                    :row="tableData(itemSetting.prop)[0]"
                                    :config="scoreItem.formList"
                                    :col="scoreItem.col"
                                    :labelWidth="scoreItem.labelWidth">
                                    <template v-slot:form-append="{form, itemSetting, rIndex}">
                                        <el-form-item
                                            :label="itemSetting.label"
                                            :prop="itemSetting.prop"
                                            :label-width="scoreItem.labelWidth">
                                            <span class="custom-span" :title="form[itemSetting.prop]">
                                                {{ form[itemSetting.prop] }}
                                            </span>
                                        </el-form-item>
                                    </template>
                                </dc-form>
                            </div>
                                </div>
                    </div>
                    <!-- <span v-else-if="itemSetting.prop === 'referenceFile'">
                        <div class="file-infos">
                            <template v-if="dictData[itemSetting.dictKey]">
                                <el-button
                                    type="text"
                                    plain
                                    @click="handleDownload('ALL', '参考附件模板.zip')">
                                    下载全部
                                </el-button>
                                <div
                                    class="file-list"
                                    v-for="(value, key, index) in dictData[itemSetting.dictKey]"
                                    :key="key">
                                    <div
                                        class="file-name"
                                        :title="value">
                                        {{`${index+1}. `}}
                                        {{ value }}
                                    </div>
                                    <div class="delete-btn">
                                        <el-button
                                            type="text"
                                            plain
                                            @click="handleDownload(key, value)">
                                            下载
                                        </el-button>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </span> -->
                    <span v-else-if="!itemSetting.optionsKey" class="custom-span" :title="form[itemSetting.prop]">
                        {{(form[itemSetting.prop] || form[itemSetting.prop] === 0) ? form[itemSetting.prop] : '--'}}
                    </span>
                </template>
            </collapse-infos>
            <!-- iflow区域 -->
            <el-collapse
                v-if="detailDataLocal.mipFlowId"
                class="contract-collapse detail-more-collapse"
                value="mipInfo">
                <el-collapse-item title="提交审批" name="mipInfo">
                    <div class="header-operation">
                        <lots-button
                            size="mini"
                            type="primary"
                            @click="submitMip"
                            v-if="readonly"
                            :loading="btnloading">
                            mip提交
                        </lots-button>
                    </div>
                    <iframe
                        class="ifr"
                        :src="mipUrl"
                        v-if="!btnloading" />
                </el-collapse-item>
            </el-collapse>
        </div>
    </paper>
</template>

<script>
import { ref, watch, computed, getCurrentInstance, onActivated } from '@vue/composition-api';
import paper from '@/components/core/Paper.vue';
import lotsButton from '@/components/lots/lotsButton';
import CollapseInfos from '@/modules/mdm/views/contractModules/components/collapseInfos/CollapseInfos.vue';
import lotsUploadButton from '@/components/lots/oss/UploadBtn';
import { queryTeamAccountChange, referenceFileDownload } from '@mdm/api/contractManage/strategicCooperationIntroduceUpdate.js';
import { approveProcess } from '@/modules/mdm/api/contractManage/detailMainApi.js';
import { contractWorkflowList } from '@mdm/api/contractManage/contractFootPageApi.js';
import { Message } from 'element-ui';
import store from '@/store/index';
import utils from '@/components/utils/common.js';
import { collapse, scoreConfig } from './config.js';
import _ from 'lodash';
import configurl from '@/config/user.env';
import { getProcessInfoApi } from '@mdm/api/contractManage/mipIframeApi.js';
import dcForm from '@/modules/mdm/components/form/Index.vue';
const { MAGIC_NUMBER } = utils;
export default {
    name: 'strategicCooperationIntroduceUpdate',
    components: { paper, lotsButton, CollapseInfos, lotsUploadButton, dcForm },
    props: {
        detailPageState: {
            type: String,
            default() {
                return '';
            }
        },
        detailData: {
            type: Object,
            return: () => {}
        }
    },
    /* eslint-disable max-lines-per-function */
    setup(props, setupContext) {
        const ctx = getCurrentInstance(); // 通过该方法访问当前组件的实例对象,相当于this
        const config = _.cloneDeep(collapse);
        const detailLoading = ref(false);
        const btnloading = ref(false);
        const disabledFlag = ref(false);
        const code = ref('');
        const readonly = ref(true);
        const detailDataLocal = ref({
            accessWarn: '战略合作模式分为：A类（系统+底盘+业务开发+品牌新增长）、B类（系统+业务开发+品牌新增长）、C类（商流系统+业务开发+品牌新增长）合作模式。',
            mipFlowId: '',
            relatedFiles: [], // 相关附件
            salesChannelsList: [], // 商机关联销售渠道明细
            categoryStructureList: [], // 商机关联品类结构明细
            proxyBrandList: [] // 商机关联代理品牌明细
        });
        const tableData = computed(() => {
            function getTableData(prop) {
                return detailDataLocal.value[prop];
            }
            return getTableData;
        });
        // eslint-disable-next-line max-len
        const activeCollapses = ref(['basicInfo', 'gaikuangInfo', 'shiyongInfo', 'kehuJingyingInfo', 'kehuZhuanxingInfo', 'kehuHezuoInfo', 'qingshiInfo', 'qudaoInfo', 'pinleiInfo', 'dailiInfo', 'relatedFileInfo', 'cankaoFileInfo', 'pingfenInfo']);
        const mipUrl = ref('');
        const lodash = computed(() => { return _; });
        const COLLAPSE = computed(() => { // 配置项动态计算
            config.forEach((v, i) => {
                v.formList.forEach((v2, i2) => {
                    if (readonly.value) { // 只读状态
                        if (v2.inputType === 'textarea') {
                            v2.disabled = true;
                        } else {
                            v2.type = 'custom';
                        }
                    } else { // 编辑状态
                        v2.type = (v2.sourceType || v2.type);
                        // 除了固定只读的字段，其他的取消只读状态
                        // eslint-disable-next-line max-len
                        const readonlyKeys = ['overallRiskInfo', 'coverRegion', 'brandNewGrowth', 'businessSystemPlan', 'b2BTransformation', 'newChannelOperation', 'cEndOperat', 'logisticsSystemPlan', 'logisticsSpecialization', 'brandExpansion', 'relationshipOppor'];
                        !readonlyKeys.includes(v2.prop) && (v2.disabled = false);
                    }
                });
            });
            return config;
        });
        const newScoreConfig = computed(() => {
            const newConfig = _.cloneDeep(scoreConfig);
            let scoreKeys = [];
            if (tableData.value('ratingScoreList')?.length) {
                scoreKeys = Object.keys(tableData.value('ratingScoreList')[0]);
            }
            tableData.value('ratingScoreList');
            newConfig.forEach(item => {
                item.formList && item.formList.forEach(sub => {
                    sub.visible = scoreKeys.includes(sub.prop); // 有值才展示相应字段
                });
            });
            // 提取formList配置项不为空的项
            const resultArr = newConfig.filter(item => item.formList.filter(i => i.visible).length);
            return resultArr;
        });
        const tableHeight = computed(() => {
            function getHeight(prop) {
                const length = detailDataLocal.value[prop].length;
                return length ? length * MAGIC_NUMBER.THIRTY_SIX + MAGIC_NUMBER.FOURTY_ONE + 1 + 'px' : MAGIC_NUMBER.FIFTY_ONE + 7 + MAGIC_NUMBER.FIFTY + 'px';
            }
            return getHeight;
        });
        const newMip = ref(false);
        const refleshPage = (customerChangeId = ctx.$route.query.customerChangeId) => {
            _initData(customerChangeId);
        };
        // 处理详情返回数据格式
        const formatData = (data) => {
            const newData = _.cloneDeep(data);
            !newData.relatedFiles && (newData.relatedFiles = []);
            return newData;
        };
        const { userName, userCode } = store.getters.user;
        // eslint-disable-next-line complexity
        const _initData = async (id) => {
            const { customerChangeId } = ctx.$route.query;
            let res = null;
            detailLoading.value = true;
            if (id || customerChangeId) {
                res = await queryTeamAccountChange({ customerChangeId: id || customerChangeId });
                readonly.value = true;
            }
            detailLoading.value = false;
            if (res?.code === '0' && res.data) {
                if (res.data.applicationId) {
                    // 获取最新流程信息
                    detailLoading.value = true;
                    const iflowRes = await contractWorkflowList({ contractCode: res.data.applicationId });
                    detailLoading.value = false;
                    if (+iflowRes.code === 0 && iflowRes.data && iflowRes.data.list && iflowRes.data.list.length) {
                        const { mipId, mipType, mipStatus } = iflowRes.data.list[0];
                        const publicPrarms = { fdId: mipId, loginName: userCode };
                        // 获取流程当前节点、当前操作人信息
                        detailLoading.value = true;
                        const currentNodeRes = await getProcessInfoApi(publicPrarms);
                        detailLoading.value = false;
                        let mipNode = '';
                        let handlePerson = '';
                        // eslint-disable-next-line max-depth
                        if (currentNodeRes && +currentNodeRes.code === 0 && currentNodeRes.data
                            && currentNodeRes.data.currNodes && currentNodeRes.data.currNodes.length) {
                            currentNodeRes.data.currNodes.forEach((item, index) => {
                                mipNode += item.nodeName + `${index === currentNodeRes.data.currNodes.length - 1 ? '' : '、'}`;
                                if (item.handlers && item.handlers.length) {
                                    item.handlers.forEach((sub, index) => {
                                        handlePerson += sub.name + `${index === item.handlers.length - 1 ? '' : '、'}`;
                                    });
                                }
                            });
                        }
                        const object = {
                            ...res.data, // 表单原始数据
                            mipFlowId: mipId, // 流程id
                            mipType, // 流程类型
                            mipStatus, // 流程状态
                            mipNode, // 当前节点
                            handlePerson // 当前节点操作人
                        };
                        handleInfosChange({ form: formatData(object) });
                    } else {
                        handleInfosChange({ form: formatData(res.data) });
                    }
                } else {
                    handleInfosChange({ form: formatData(res.data) });
                }
            }
        };
        const isHandleEdit = ref(false);
        const handleInfosChange = (formObjData = {}) => { // 表单组件编辑回调，更新本地数据
            if (formObjData.form && formObjData.form.businessType && !formObjData.form.businessType.includes('快递')) {
                // 业务类别不包含快递时，清空聚焦业态和是否控仓；
                formObjData.form.focusBusinessFormats = '';
                formObjData.form.isWarehouse = '';
            }
            const { itemSetting } = formObjData;
            // 监控表单是否编辑过
            isHandleEdit.value = Boolean(itemSetting);
            if (!itemSetting) { // 页面初始化、刷新时走：
                let rating, score;
                if (formObjData.form.ratingScoreList?.length) { // 提取评分数据到外层
                    rating = formObjData.form.ratingScoreList[0].rating;
                    score = formObjData.form.ratingScoreList[0].score;
                }
                detailDataLocal.value = Object.assign({}, detailDataLocal.value, formObjData.form, { rating, score });
                if (detailDataLocal.value.mipFlowId) {
                    handleCreate(); // 渲染iflow流程
                }
                setupContext.refs.upholdHealder && setupContext.refs.upholdHealder.clearValidate(); // 重置校验报错
                setupContext.refs.upholdHealder && setupContext.refs.upholdHealder.refleshPage(detailDataLocal.value); // 刷新表单子组件
            } else { // collapse组件编辑回调时走：
                const { relatedFiles } = detailDataLocal.value;
                // 除了插槽参数以外，其他参数取最新编辑的参数更新(因：插槽的编辑不会触发collapse组件change事件回调，因此change事件回调的插槽的参数是旧数据不能取)
                detailDataLocal.value = Object.assign(
                    {},
                    detailDataLocal.value,
                    formObjData.form,
                    { relatedFiles } // 保证插槽数据用最新的
                );
            }
        };
        const handleCreate = () => {
            mipUrl.value = '';
            mipUrl.value = `${configurl.iflowUrl}?fdId=${detailDataLocal.value.mipFlowId}&fdTemplateKey=CRM_team_account_to_admit&userCode=${userCode}&userName=${userName}`;
            createMetaNode();
            window.addEventListener('message', messageFunc, false);
            newMip.value && setTimeout(() => {
                const element = document.getElementsByClassName('mip-iframe')[0];
                element.scrollTo(0, MAGIC_NUMBER.TWO_THOUSAND); // 页面滚动到MIP审批位置
                newMip.value = false;
            }, MAGIC_NUMBER.FOUR_HUNDRED);
        };
        const createMetaNode = () => {
            const mipmeta = document.getElementById('mipmeta');
            if (mipmeta) {
                return false;
            }
            const meta = document.createElement('meta');
            meta.httpEquiv = 'Content-Security-Policy';
            meta.content = 'upgrade-insecure-requests';
            meta.id = 'mipmeta';
            document.getElementsByTagName('head')[0].appendChild(meta);
        };
        const messageFunc = (event) => {
            if (event.data && event.data.data && event.data.success) {
                const { customerChangeId, applicationId } = detailDataLocal.value;
                const { origin, pathname } = window.location;
                const params = event.data.data || {};
                const { tenantCode } = store.getters.currentTenant;
                params.tenantCode = tenantCode;
                // params.currentApplicationCode = this.applicationCode;
                params.currentApplicationCode = 'APP201904230047';
                if (params.formParam) {
                    // params.formParam.formInstanceId = this.$route.query.id;
                    params.formParam.formInstanceId = applicationId;
                    const { processParam } = params;
                    let isToOtherPersons = '';
                    if (processParam.operationType === 'circulate' && processParam.toOtherPersons) {
                        isToOtherPersons = processParam.toOtherPersons; // 被传阅的人
                    }
                    // eslint-disable-next-line max-len
                    params.formParam.fdUrl = `${origin}${pathname}#/strategicCooperationIntroduceUpdate?customerChangeId=${customerChangeId}${isToOtherPersons ? `&isToOtherPersons=${isToOtherPersons}` : ''}`; // mip代办跳转URL
                }
                btnloading.value = true;
                approveProcess(params).then(res => {
                    if (res && +res.code === 0) {
                        Message.success('提交成功');
                        ctx.$router.go(0);
                    } else {
                        Message.error(res.msg);
                    }
                }).finally(() => {
                    btnloading.value = false;
                });
            }
        };
        // eslint-disable-next-line complexity
        const submitMip = async () => {
            const scoreLimitation = 60; // 限制60分
            const { applicationId, mipFlowId, ratingScoreList } = detailDataLocal.value;
            if ((ratingScoreList.length && ratingScoreList[0].score <= scoreLimitation) || !ratingScoreList.length || !ratingScoreList[0]?.score) {
                return Message.warning('修改战略合作类型，需要重新进行评级，且评分必须大于60分');
            }
            if (applicationId) {
                const iflowRes = await contractWorkflowList({ contractCode: applicationId });
                if (+iflowRes.code === 0) {
                    if (iflowRes.data && iflowRes.data.list && iflowRes.data.list.length && iflowRes.data.list[0].mipId
                        && iflowRes.data.list[0].mipId === mipFlowId) {
                        // 通过的，不做卡控
                    } else {
                        Message.warning('页面审批流程信息已过期，请刷新页面再试');
                        return false;
                    }
                } else {
                    return false;
                }
            }
            const content = window;
            content.frames && content.frames.length && content.frames[0]
                && content.frames[0].postMessage('mbpm_getApprovalData', mipUrl.value);
            content.frames && content.frames.length && content.frames[1]
                && content.frames[1].postMessage('mbpm_getApprovalData', mipUrl.value); // 解决接入了智能客服后，content.frames[0]被占用了的问题
        };
        // eslint-disable-next-line complexity
        const upLoadData = async (form, itemSetting, data) => {
            const { prop } = itemSetting;
            form[prop] = form[prop] ? form[prop] : [];
            if (data && +data.code === 0) {
                form[prop].push({ fileName: data.data.name, fileUrl: data.data.downUrl });
                const formRefs = setupContext.refs.upholdHealder.$refs.dcForm;
                if (prop === 'relatedFiles') {
                    if (itemSetting.rule[0].required) {
                        const targetRef = formRefs.find(item => item._props.refName === 'relatedFileForm');
                        targetRef.$refs['relatedFileForm'].validateField(prop);
                    }
                    detailDataLocal.value = Object.assign({}, detailDataLocal.value, form);
                    isHandleEdit.value = true;
                }
            }
        };
        const handleDeleteFile = (form, itemSetting, index) => {
            const { prop } = itemSetting;
            form[prop].splice(index, 1);
            detailDataLocal.value = Object.assign({}, detailDataLocal.value, form);
            const formRefs = setupContext.refs.upholdHealder.$refs.dcForm;
            if (prop === 'relatedFiles' && itemSetting.rule[0].required) {
                const targetRef = formRefs.find(item => item._props.refName === 'relatedFileForm');
                targetRef.$refs['relatedFileForm'].validateField(prop);
            }
            isHandleEdit.value = true;
        };
        const handleDownload = (code, fileName) => {
            referenceFileDownload({ code, fileName }).then(res => {
                if (+res.code === 0) {
                    Message.success('下载成功');
                }
            });
        };
        const tableKey = ref(false);
        onActivated(async() => {
            tableKey.value = !tableKey.value;
        });
        watch( // 注意：加了immediate: true的话，watch监听要在放在最后，否则报错涉及的调用方法找不到！
            () => ctx.$route,
            (newVal) => {
                if (newVal.name === 'strategicCooperationIntroduceUpdate') {
                    const { customerChangeId } = detailDataLocal.value;
                    !customerChangeId && newVal.query.customerChangeId && refleshPage();
                }
            },
            { immediate: true } // 不设置的话，初始化加载时不会执行
        );
        return {
            config,
            COLLAPSE,
            detailLoading,
            btnloading,
            disabledFlag,
            code,
            detailDataLocal,
            mipUrl,
            refleshPage,
            handleInfosChange,
            submitMip,
            readonly,
            upLoadData,
            handleDeleteFile,
            activeCollapses,
            tableHeight,
            tableKey,
            lodash,
            tableData,
            handleDownload,
            newScoreConfig
        };
    }
};
</script>
<style lang="less">
@scrollbarThumbColor: rgba(0,0,0, .2);
@scrollbarThumbColorHover: rgba(0,0,0, .4);

.mip-iframe {
    // overflow: auto;
    display: flex;
    flex-direction: column;
    .top-box {
        .header-box {
            width: 100%;
            h4 {
                text-align: center;
                font-size: 20px;
                margin: 0;
                color: #606266;
            }
            .header-info-crm {
                width: 100%;
                display: flex;
                font-size: 14px;
                border-bottom: 1px solid #dde0e6;
                margin-bottom: 10px;
                padding-bottom: 5px;
                .tips {
                    flex: 1;
                    // color: red;
                    color: #606266;
                    line-height: 40px;
                }
                .apply-info {
                    width: 200px;
                    line-height: 20px;
                    color: #606266;
                    margin-left: 10px;
                }
            }
        }
        .detail-toolBar {
            .item-flow-right {
                float: right;
                &.refresh {
                    margin-left: 10px;
                }
            }
        }
    }
    .strategic-cooperation-introduce-apply {
        overflow: auto;
        .upload-demo {
            display: inline-block;
        }
        .down-mould-btn {
            margin-left: 5px;
        }
        .upload-tips {
            display: inline-block;
            margin-left: 10px;
            line-height: 28px;
            font-size: 12px;
            color: #606266;
        }
        .file-infos {
            width: 100%;
            padding: 0 10px 0 0;
            .file-list {
                width: 100%;
                padding: 2px 0 2px 4px;
                display: flex;
                // justify-content: space-between;
                .file-name {
                    // flex: 1;
                    &.upload {
                        width: 500px;
                    }
                    padding-right: 5px;
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: inline-block;
                }
                .view-btn, .delete-btn {
                    width: 200px;
                    display: flex;
                    a {
                        text-decoration: none;
                        color: #4285F5;
                    }
                    .el-button {
                        margin-left: 10px;
                    }
                }
            }
        }
        .el-collapse-item__content {
            padding-bottom: 5px;
        }
        .el-collapse-item__arrow {
            margin: 0 8px 0 5px;
        }
        .el-button.is-disabled.is-plain,
        .el-button.is-disabled.is-plain:focus,
        .el-button.is-disabled.is-plain:hover {
            border-color: #FFF;
        }
        .width-small {
            width: 340px;
        }
        .width-middle {
            width: 500px;
        }
        .width-large {
            width: 1200px;
        }
        .el-form-item--mini.el-form-item, .el-form-item--small.el-form-item {
            margin-bottom: 2px;
        }
        .el-col.el-col-23.form-item {
            .el-form-item.el-form-item--mini {
                margin-bottom: 18px;
            }
        }
        .el-collapse-item__header {
            height: 32px;
        }
        .detail-form-collapse {
            padding-right: 10px;
        }
        .score-config-item-box {
            .item-tittle {
                font-size: 14px;
                font-weight: 600;
                color: #606266;
                margin-left: 28px;
            }
        }
        .rating-score-list-box {
            .el-collapse {
                border-bottom: none;
                .el-collapse-item__header {
                    border-bottom: none;
                }
                .el-collapse-item__wrap {
                    border-bottom: none;
                }
            }
        }
    }
    .ifr {
        width: 100%;
        height: 100vh;
        border: none;
    }
    .el-collapse-item__header {
        color: #606266;
        font-size: 14px;
        font-weight: 600;
    }
    .header-operation {
        margin-top: 10px;
    }
}
.no-auth {
    font-size: 16px;
}
.my-tooltip-box {
    width: 800px;
}
</style>
