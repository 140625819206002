import request from '@/utils/http';
import utils from '@/utils/common';
const apiCrmHost = 'api-lcrm';

// 查询战合引入信息-yjl
export const queryTeamAccountChange = (data) => {
    return request({
        url: apiCrmHost + '/neoCrm/teamAccountChange/query',
        method: 'post',
        data
    });
};
// 参考附件下载
export const referenceFileDownload = (params) => {
    return request({
        url: apiCrmHost + '/file/download',
        method: 'get',
        responseType: 'blob',
        params
    }).then(res => {
        utils.downloadStream(res, params.fileName);
    });
};
