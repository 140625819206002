/*eslint-disable*/
import store from '@/store';
const tenantCode = store.getters.currentTenant.tenantCode;
// 表单配置信息
export const collapse = [
    {
        title: '客户概况',
        ref: 'gaikuangForm',
        formName: 'gaikuangInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '客户名称',
                prop: 'customerName',
                value: 'customerName',
                type: 'custom',
            },
            {
                label: '原战略合作类型',
                prop: 'teamType',
                value: 'teamType',
                type: 'custom'
            },
            {
                label: '修改后战略合作类型',
                prop: 'changeTeamType',
                value: 'changeTeamType',
                type: 'custom'
            },
            {
                label: '渠道类型',
                prop: 'salesChannels',
                value: 'salesChannels',
                type: 'custom'
            },
            {
                label: '终端数量',
                prop: 'coverStores',
                value: 'coverStores',
                type: 'custom'
            },
            {
                label: '品类结构',
                prop: 'categoryStructure',
                value: 'categoryStructure',
                type: 'custom'
            },
            {
                label: '覆盖区域',
                prop: 'coverRegion',
                value: 'coverRegion',
                // type: 'custom',
                sourceType: 'input',
                inputType: 'textarea',
                placeholder: '（空）',
                type: 'input',
                span: 24,
                rows: 3,
                disabled: true
            },
        ]
    },
    {
        title: '评分评级',
        ref: 'pingfenForm',
        formName: 'pingfenInfo',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '评级',
                prop: 'rating',
                value: 'rating',
                type: 'custom',
            },
            {
                label: '评分',
                prop: 'score',
                value: 'score',
                type: 'custom'
            }
            // {
            //     label: '评分表明细',
            //     prop: 'ratingScoreList',
            //     value: 'ratingScoreList',
            //     type: 'custom',
            //     span: 24
            // }
        ]
    },
    {
        title: '评分明细',
        ref: 'pingfenDetailForm',
        formName: 'pingfenDetailInfo',
        col: 4,
        labelWidth: '0px',
        visible: true,
        formList: [
            {
                label: '',
                prop: 'ratingScoreList',
                value: 'ratingScoreList',
                type: 'custom',
                span: 24
            }
        ]
    },
    {
        title: '销售渠道',
        ref: 'qudaoForm',
        formName: 'qudaoInfo',
        col: 4,
        labelWidth: '0px',
        visible: true,
        formList: [
            {
                label: '',
                prop: 'salesChannelsList',
                value: 'salesChannelsList',
                type: 'custom',
                span: 24,
                columns: [
                    {
                        label: '销售渠道',
                        minWidth: 160,
                        prop: 'salesChannels'
                    },
                    {
                        label: '覆盖终端门店数',
                        prop: 'coverStores',
                        minWidth: 160
                    }
                ]
            },
        ]
    },
    {
        title: '品类结构',
        ref: 'pinleiForm',
        formName: 'pinleiInfo',
        col: 4,
        labelWidth: '0px',
        visible: true,
        formList: [
            {
                label: '',
                prop: 'categoryStructureList',
                value: 'categoryStructureList',
                type: 'custom',
                span: 24,
                columns: [
                    {
                        label: '品类',
                        minWidth: 160,
                        prop: 'categoryStructure'
                    },
                    {
                        label: '年度规模(万)',
                        prop: 'annualScale',
                        minWidth: 160
                    },
                    {
                        label: '核心品牌',
                        prop: 'coreBrand',
                        minWidth: 160
                    }
                ]
            },
        ]
    },
    {
        title: '代理品牌',
        ref: 'dailiForm',
        formName: 'dailiInfo',
        col: 4,
        labelWidth: '0px',
        visible: true,
        formList: [
            {
                label: '',
                prop: 'proxyBrandList',
                value: 'proxyBrandList',
                type: 'custom',
                span: 24,
                columns: [
                    {
                        label: '品牌商',
                        prop: 'teamCustomerGroup',
                        minWidth: 160
                    },
                    {
                        label: '行业大类',
                        prop: 'industryCategories',
                        minWidth: 160
                    },
                    {
                        label: '代理层级',
                        prop: 'agentLevel',
                        minWidth: 160
                    },
                    {
                        label: '年度规模(万)',
                        prop: 'annualScale',
                        minWidth: 160
                    },
                    {
                        label: '代理开始时间',
                        prop: 'proxyStartTime',
                        minWidth: 160
                    },
                    {
                        label: '代理结束时间',
                        prop: 'proxyEndTime',
                        minWidth: 160
                    }
                ]
            },
        ]
    }
];
// 评分明细配置
export const scoreConfig = [
    {
        title: '资质',
        formName: 'qualifications',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '成立年限',
                prop: 'establish',
                value: 'establish',
                type: 'custom',
            },
            {
                label: '操盘手',
                prop: 'trader',
                value: 'trader',
                type: 'custom',
            },
            {
                label: '推广团队',
                prop: 'marketingTeam',
                value: 'marketingTeam',
                type: 'custom',
            },
            {
                label: '办公环境',
                prop: 'officeEnvironment',
                value: 'officeEnvironment',
                type: 'custom',
            },
            {
                label: '近三年发展趋势',
                prop: 'pastThreeTrends',
                value: 'pastThreeTrends',
                type: 'custom',
            }
        ]
    },
    {
        title: '物流能力',
        formName: 'logisticsCapability',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '仓库空仓/扩容面积',
                prop: 'expansionArea',
                value: 'expansionArea',
                type: 'custom',
            },
            {
                label: '仓租成本竞争力',
                prop: 'whCompete',
                value: 'whCompete',
                type: 'custom',
            },
            {
                label: '车辆数',
                prop: 'vehicles',
                value: 'vehicles',
                type: 'custom',
            },
            {
                label: '配送成本竞争力',
                prop: 'deliveryCompete',
                value: 'deliveryCompete',
                type: 'custom',
            }
        ]
    },
    {
        title: '商流能力',
        formName: 'businessFlowCapability',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '商流规模',
                prop: 'businessScale',
                value: 'businessScale',
                type: 'custom',
            },
            {
                label: '运作品类',
                prop: 'operateCategory',
                value: 'operateCategory',
                type: 'custom',
            },
            {
                label: '运营品牌',
                prop: 'proxyBrand',
                value: 'proxyBrand',
                type: 'custom',
            },
            {
                label: '覆盖终端',
                prop: 'coverStores',
                value: 'coverStores',
                type: 'custom',
            },
            {
                label: '物流交付',
                prop: 'logisticsDelivery',
                value: 'logisticsDelivery',
                type: 'custom',
            },
            {
                label: '商品周转',
                prop: 'stockTurnover',
                value: 'stockTurnover',
                type: 'custom',
            }
        ]
    },
    {
        title: '转型',
        formName: 'transformation',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '向上（A类）',
                prop: 'up1',
                value: 'up1',
                type: 'custom',
            },
            {
                label: '向上（B/C类）',
                prop: 'up2',
                value: 'up2',
                type: 'custom',
            },
            {
                label: '向下',
                prop: 'down',
                value: 'down',
                type: 'custom',
            },
            {
                label: '向前',
                prop: 'forward',
                value: 'forward',
                type: 'custom',
            },
            {
                label: '向后',
                prop: 'backward',
                value: 'backward',
                type: 'custom',
            }
        ]
    },
    {
        title: '潜力',
        formName: 'potential',
        col: 4,
        labelWidth: '170px',
        visible: true,
        formList: [
            {
                label: '合仓意愿',
                prop: 'mergeWill',
                value: 'mergeWill',
                type: 'custom',
            },
            {
                label: '转型意愿',
                prop: 'transformWill',
                value: 'transformWill',
                type: 'custom',
            },
            {
                label: '行业地位',
                prop: 'industryStatus',
                value: 'industryStatus',
                type: 'custom',
            }
        ]
    }
];
